<template>
    <div>
      <ul>
        <tree-node
          v-for="(node, index) in treeData"
          :key="index"
          :node="node"
        />
      </ul>
    </div>
  </template>
  
  <script>
  import TreeNode from './TreeNode.vue';
  
  export default {
    name: "D2Tree",

    components: {
      TreeNode,
    },
    props: {
      treeData: {
        type: Array,
        required: true,
      },
    },
  };
  </script>
  
<style scoped>
ul {
  list-style-type: none; /* Remove bullets */
  padding-left: 20px;   /* Indentation for child nodes */
  margin: 5px;            /* Remove default margin */
  text-align: left;
}
</style>