<template>
  <li>
    <span @click="toggle" style="cursor: pointer;">
      <span :class="circleIconClass">
        <i :class="nodeIconClass"></i>
      </span>
      <span :class="{selected: node.isSelected, highlighted: node.state === 1}">
        {{ node.name }}
      </span> 
    </span>
    <ul v-if="node.isOpen && hasChildren">
      <tree-node
        v-for="(child, index) in node.children"
        :key="index"
        :node="child"
      />
    </ul>
  </li>
</template>
  
  <script>
  import { emit } from './eventBus'; // Import the event bus
  export default {
    props: {
      node: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        isOpenX: false,
      };
    },
    computed: {
      hasChildren() {
        return this.node.children && this.node.children.length > 0;
      },
      nodeIconClass() {
        if (this.node.children && this.node.children.length > 0) {
          return this.node.isOpen ? 'bi bi-dash' : 'bi bi-plus';
        }
        else {
          return 'bi bi-circle empty-circle';
        }
      },
      circleIconClass() {
        if (this.node.level > 2) {
          return 'circle-icon-d34';
        }
        else if (this.node.level == 2) {
          return 'circle-icon-d23';
        }
        else if (this.node.level == 1) {
          return 'circle-icon-d23';
        }
        else {
          return 'circle-icon';
        }
      },
      spanClass() {
        // selected: node.isSelected, highlighted: node.state === 1
        var className='';
        if (this.node.selected == true) {
          className = 'selected';
        }
        if (this.node.state == 1) {
          className = className + 'highlighted';
        }
        return className;
      },
    },
    methods: {
      toggle() {
        // eslint-disable-next-line vue/no-mutating-props
        this.node.isOpen = !this.node.isOpen;
        //console.log('Node clicked(toggle):', this.node);
        emit('node-clicked', this.node);
      },
    },
  };
  </script>
  
  <style scoped>
  li {
    list-style-type: none;
    margin: 15px 0;
  }
  .highlighted {
    background-color: rgb(236, 235, 235); /* Light grey background */
    padding: 5px 10px; /* Add padding around the text */
    border-radius: 5px; /* Optional: rounded corners */
    display: inline-block; /* Ensure padding and background wrap around text */
  }
  .selected {
    font-weight: bold;
    color: rgb(191, 44, 44);
    border: 1px solid rgb(191, 44, 44); /* Slim border */
    padding: 2px 4px; /* Adjust padding for slim appearance */
    border-radius: 5px; /* Rounded corners */
    display: inline-block; /* Keeps the dimensions of the element */
    margin: 2px; /* Optional: Add some space around the element */
}
  .circle-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px; /* Circle width */
  height: 30px; /* Circle height */
  border-radius: 50%; /* Makes the background circular */
  background-color: lightgray; /* Circle background color */
  margin-right: 8px; /* Space between icon and text */
  font-size: 16px; /* Icon size */
  }
  
  .circle-icon-d34 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px; /* Circle width */
  height: 30px; /* Circle height */
  border-radius: 50%; /* Makes the background circular */
  background-color: rgb(171, 237, 184); /* Circle background color */
  margin-right: 8px; /* Space between icon and text */
  font-size: 16px; /* Icon size */
  }

  .circle-icon-d23 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px; /* Circle width */
  height: 30px; /* Circle height */
  border-radius: 50%; /* Makes the background circular */
  background-color: rgb(247, 234, 174); /* Circle background color */
  margin-right: 8px; /* Space between icon and text */
  font-size: 16px; /* Icon size */
  }
 
  .empty-circle {
    color: rgb(171, 237, 184); /* Lighter shade of grey for the empty circle (light gray) */
  }
 
  
  </style>