<template>
    <div>
    
      <NavBar
        v-bind:items="[
          {
            text: 'Seznam',
            to: { name: 'Home' },
          },
          {
              text: userName,
              to: { name: 'UserDetail', params: {id: patient.id} } 
          },
          {
              text: statPath, 
              to: { name: 'UserDetail' }
          },
        ]"
      />
 
    <div class="content-wrapper">

      <b-button 
      v-if="selectedViewType === 'Strom' && therapeuticPlanEnabled" 
      variant="primary" 
      class="floating-button4" 
      @click="toggleSelection" 
      title="Vybrat/Zrušit F2"
    >
      <i class="bi bi-check-circle" style="font-size: 1.5rem;"></i>
    </b-button>

    <b-button 
      v-if="selectedViewType === 'Strom'" 
      variant="secondary" 
      class="floating-button3" 
      @click="collapseTreeAll" 
      title="Sbalit vše"
    >
      <i class="bi bi-chevron-up" style="font-size: 1.5rem;"></i>
    </b-button>

    <b-button 
      v-if="selectedViewType === 'Strom'" 
      variant="success" 
      class="floating-button2" 
      @click="saveDiagnostics" 
      title="Uložit výběr"
    >
      <i class="bi bi-save" style="font-size: 1.5rem;"></i>
    </b-button>

    <b-button 
      v-if="selectedViewType === 'Strom' && therapeuticPlanEnabled"  
      variant="success" 
      class="floating-button1" 
      @click="editPlan" 
      title="Definovat terapeutický plán"
    >
      <i class="bi bi-file-earmark-text" style="font-size: 1.5rem;"></i>
    </b-button>

     <b-row class="mb-3" style="width: 80%; margin: 0 auto;">
        <b-form-group :label=selectedViewType label-for="tree-type">
          <b-form-radio-group v-model="selectedViewType" id="tree-type">
            <b-form-radio value="Strom">Strom</b-form-radio>
            <b-form-radio value="Graf">Graf</b-form-radio>
            <!-- <b-form-radio value="Seznam">Seznam</b-form-radio> -->
          </b-form-radio-group>
        </b-form-group>
      </b-row>

      <div class="search-container mb-3" v-if="selectedViewType === 'Strom'">
        <input
          type="text"
          v-model="searchTerm"
          placeholder="Hledej položku..."
          class="form-control"
        />
      </div>

      <b-row class="mb-3" style="width: 80%; margin: 0 auto;">
        <component :is="currentTreeComponent" 
                   v-if="selectedViewType === 'Strom'" 
                   :treeData="treeData.children"/>
        <component :is="currentTreeComponent" 
                   v-if="selectedViewType === 'Graf'" 
                   :data="treeData" 
                   @node-clicked="handleNodeClick"></component>
      </b-row>
     

   <b-row  v-if="selectedViewType === 'Seznam'" class="mb-3" style="width: 80%; margin: 0 auto;"> <!-- Added styles here -->
      <b-row>
        <b-form-select class="select-spacing"
          id="resA"
          v-model="valueA"
          :options="listA"
          value-field="id"
          text-field="name"
        ></b-form-select>
      </b-row>
      <b-row>
        <b-form-select class="select-spacing"
          id="resB"
          v-model="valueB"
          :options="listB"
          value-field="id"
          text-field="name"
        ></b-form-select>
      </b-row>
      <b-row>
        <b-form-select class="select-spacing"
          id="resC"
          v-model="valueC"
          :options="listC"
          value-field="id"
          text-field="name"
        ></b-form-select>
      </b-row>
      <b-row>
        <b-form-select class="select-spacing"
          id="resD"
          v-model="valueD"
          :options="listD"
          value-field="id"
          text-field="name"
        ></b-form-select>
      </b-row>
      <b-row>
        <b-form-select class="select-spacing"
          id="resE"
          v-model="valueE"
          :options="listE"
          value-field="id"
          text-field="name"
        ></b-form-select>
      </b-row>
    </b-row>
  
     <b-row class="mb-3" style="width: 90%; margin: 0 auto;">
   <b-col>
      <b-button
          v-if="selectedViewType !== 'Strom'"
          variant="danger"
          @click="saveDiagConfig"
          class="float-right m-2"
          ><i class="bi bi-save"></i> Uložit konfiguraci</b-button
        >
    </b-col>
    <b-col>
      <b-button
          v-if="selectedViewType !== 'Strom'"
          variant="success"
          @click="editPlan"
          class="float-right m-2"
          ><i class="bi bi-save"></i> Dokument</b-button
        >
    </b-col>
    <b-col>
      <b-button
          variant="primary"
          @click="editNode"
          v-if="editEnabled"
          class="float-right m-2"
          ><i class="bi bi-pencil"></i> Upravit</b-button
        >
    </b-col>
    <b-col>
      <b-button
          variant="primary"
          @click="addNode"
          v-if="editEnabled"
          class="float-right m-2"
          ><i class="bi bi-plus"></i> Vložit</b-button
        >
    </b-col>
    <b-col>
      <b-button
          variant="danger"
          @click="deleteNode"
          v-if="editEnabled"
          class="float-right m-2"
          ><i class="bi bi-trash"></i> Smazat</b-button
        >
    </b-col>
    <b-col>
      <b-button
          variant="secondary"
          @click="importStat"
          v-if="editEnabled"
          class="float-right m-2"
          ><i class="bi bi-upload"></i> Import CSV source</b-button
        >
    </b-col>
  </b-row>

    </div>

    <ModalDialog ref="modalTestList"  
    :message = dialogMessage
    :entrylabel=dialogentryLabel
    :isVisible = showModal
    :showEdit=dialogShowEdit
    :enterValue=dialogDefaultValue
    @update:response="handleDialogClose" />

    <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload"/>

  </div>
  </template>
    
  <script>
   
    import NavBar from "../components/navbar.vue";
    import D3Tree from '../components/d3tree.vue';
    import { getStat, saveStat, saveUserStat } from '../services/api'; //, saveStat , saveUserStat, , saveUserStat
    import ModalDialog from "../components/modalDialog.vue";
    import D2Tree from "../components/d2tree.vue";
    import { on } from '../components/eventBus'; // Import the event bus
      
    export default {
      name: 'DiagnosticsView',
      components: {
        D3Tree,
        NavBar,
        ModalDialog,
        D2Tree,
    },
      data() {
      return {
        statPath: '',
        statPaths: [],
        statCodes: [],
        patient: null,
        userName: "",
        treeData: {
          name: "Statistika", id: "0", state: 1,
          children: []
        },
        statItems : [],
        listA : [],
        listB : [],
        listC : [],
        listD : [],
        listE : [],
        selectedItem: null,
        valueA: null,
        valueB: null,
        valueC: null,
        valueD: null,
        valueE: null,
        modalVisible: false,
        editName: '',
        showModal: false,
        dialogMessage: "",
        dialogentryLabel: "",
        dialogShowEdit: false,
        dialogResponseValue: "",
        dialogDefaultValue: "",
        dialogResponseCallBack: null,
        editedStat: {id: 0, date: "", path: "", note: "", parent_id: "", code: "", tlink: ""},
        selectedViewType: 'Strom',
        editEnabled: false,
        searchTerm: '',
        therapeuticPlanEnabled: false,

        data: [
        {
          name: 'Root 1', id: 0,
          children: [
            { name: 'Child 1-1', id: 0, children: [] },
            { name: 'Child 1-2', id: 0, children: [] },
          ],
        },
        // {
        //   name: 'Root 2',
        //   children: [
        //     {
        //       name: 'Child 2-1',
        //       children: [
        //         { name: 'Grandchild 2-1-1', children: [] },
        //         { name: 'Grandchild 2-1-2', children: [

                
        //           { name: 'Grandchild 3-1-1', children: [] },
        //           { name: 'Grandchild 3-1-2', children: [] },
              
        //         ] },
        //       ],
        //     },
        //   ],
        // },
      ],
        
      };
    },

    computed: {
    currentTreeComponent() {
      return this.selectedViewType === 'Strom' ? D2Tree : D3Tree;
    },
    // navItems() {
    //   return [
    //     { text: 'Seznam', to: { name: 'Home' } },
    //     { text: this.userName, to: { name: 'UserDetail', params: { id: this.patient.id } } },
    //     { text: this.statPath, to: { name: 'UserDetail' } },
    //   ];
    // },
  },
  
    methods: {
      toggleSelection() {
        if (this.selectedItem != null) {

          // to do : if level > 3, check if parent level 3 is selected 
          if ((!this.selectedItem.isSelected) && (this.selectedItem.level == 3)) {
            // exclusive selection?
            // check already selected level 3
            // query: reset all level 3 items and its children

            // checks any selection at level 3
            if (this.traverseAndCheckSelection(this.treeData.children)) {
              console.log("LEVEL 3 exc - already selected");

              this.dialogMessage = "Úroveň 3 už vybrána, přepnout?";
              this.dialogShowEdit = false;
              this.dialogDefaultValue = "ok";
              this.dialogResponseCallBack = () => {
                this.traverseAndResetSelection(this.treeData.children);
                // copy
                  this.selectedItem.isSelected = !this.selectedItem.isSelected;

                  console.log("selectedItem.code: " + this.selectedItem.code + " :" + this.selectedItem.name);
                  console.log("selectedItem.level: " + this.selectedItem.level);

                  //const pathToRoot = this.getPathToRoot(this.treeData, this.selectedItem).join(' / ');
                  if (this.selectedItem.isSelected) {
                    // const index = this.statPaths.findIndex(path => path === pathToRoot);
                    // if (index == -1) {
                    //   this.statPaths.push(pathToRoot);
                    // }

                    const index = this.statCodes.findIndex(code => code == this.selectedItem.code);
                    if (index == -1) {
                      this.statCodes.push(this.selectedItem.code);
                    }
                  }
                  else
                  {
                    //remove from statPaths
                    const index = this.statCodes.findIndex(code => code == this.selectedItem.code);
                    if (index > -1) {
                      this.statCodes.splice(index, 1);
                    }
                  }
                // /copy
              };
              this.showModal = true;
              return;
            }
          }

          this.selectedItem.isSelected = !this.selectedItem.isSelected;

          console.log("selectedItem.code: " + this.selectedItem.code + " :" + this.selectedItem.name);
          console.log("selectedItem.level: " + this.selectedItem.level);

          //const pathToRoot = this.getPathToRoot(this.treeData, this.selectedItem).join(' / ');
          if (this.selectedItem.isSelected) {
            // const index = this.statPaths.findIndex(path => path === pathToRoot);
            // if (index == -1) {
            //   this.statPaths.push(pathToRoot);
            // }

            const index = this.statCodes.findIndex(code => code == this.selectedItem.code);
            if (index == -1) {
              this.statCodes.push(this.selectedItem.code);
            }
          }
          else
          {
            //remove from statPaths
            const index = this.statCodes.findIndex(code => code == this.selectedItem.code);
            if (index > -1) {
              this.statCodes.splice(index, 1);
            }
          }

          //console.log("toggleSelection....codes  "+ this.statCodes.join('/'));
         
        }
        
      },
  
      openEditDialog() {
      if (this.selectedItem) {
        this.editName = this.selectedItem.data.name; // Load current name
        this.modalVisible = true; // Open modal
      } else {
        // Optionally, notify user no item is selected
        alert("Please select an item to edit.");
      }
    },
    
    closeModal() {
      this.modalVisible = false;
    },
    handleDialogClose(newVal) {
      console.log(newVal)
      this.dialogResponseValue = newVal;
      this.showModal = false;
      if (newVal != "") {
        this.dialogResponseCallBack();
      }
    },
      
    async loadDiagnostics() {
        console.log("Edit diagnostics");

        const res = await getStat();
        console.log(res.data);
        this.statItems = JSON.parse(res.data.data);

        // const ress = await saveStat("bbb");
        // console.log(ress.data);
        
      //  const response = await getDiagList();
        //console.log("get diaglist");
        //console.log(response.data.data);
      //  this.statItems = response.data.data;
        // create tree structure from the list
        // this.statItems = [
        //   { id: "1", state: 0, code: 'A', name: 'Node A' ,parentId: "0" },
        //   { id: "2", state: 0, code: 'B', name: 'Node B' ,parentId: "0" },
        //   { id: 3, state: 0, code: 'C', name: 'Node C', parentId: 1 },
        //   { id: 4, state: 0, code: 'D', name: 'Node D', parentId: 2 },
        //   { id: 5, state: 0, code: 'E', name: 'Node E', parentId: 2 },
        //   { id: 6, state: 0, code: 'G', name: 'Node G', parentId: 5 },
        //   { id: 7, state: 0, code: 'H', name: 'Node H', parentId: 5 },
        // ];

        //console.log(this.statItems);

        // Create the tree structure
        this.treeData.children = this.createTree(this.statItems);
        // console.log("tree data");
        // console.log(this.treeData);

        //this.fillList(this.listA, 0);
        
        
        // expand all parent nodes down to the selected
        if (this.statCodes)
        {
          for (const code of this.statCodes) {
            const levels = code.split('.').map((level) => level.trim());
            var label = "";
            for (let i = 0; i < levels.length; i++) {
              label += levels[i] + (i < levels.length - 1 ? '.' : ''); // Add '.' except after the last level
              // add dot as te last char is none is there
              //  if (!label.endsWith('.')) {
              //   label += '.';
              //  }
              //console.log(label);
              this.openNodeInTreeByCode(this.treeData.children, label);
            }
            this.selectNodeInTreeByCode(this.treeData.children, code);
          }
        }
        
        //this.showPathInTree(this.statPath);

      },

      fillList(list, level) {
        list.length = 0;
        this.statItems.forEach(item => {
          if (item.parentId == level) {
            list.push(item);
          }
        });
      
      },

    //   selectItem(item) {
    //   this.selectedItem = item; // update the selected item
    //   console.log(`Selected item: ${item}`);
    // },

    constructPathsFromCodes() {
      this.statPaths.length = 0;
        for (const code of this.statCodes) {
          const node = this.getNodeInTreeByCode(this.treeData.children, code);
          if (node) {
            const pathToRoot = this.getPathToRoot(this.treeData, node);
            this.statPaths.push(pathToRoot);
          }
        } 
        return  this.statPaths.join(' ; ')
    },

    async saveDiagnostics() {
        console.log("save diagnostics");
        this.$store.state.config.statResult = this.statPath;

        //console.log(this.editedStat);

        this.editedStat.path =  this.constructPathsFromCodes();
        var diagnosis = "";
        var operation = "";

        this.statPath.split(';').forEach((path) => {
          const pathParts = path.split(' / ');
                    
          //console.log("-path-");
          //console.log(path);

          diagnosis = pathParts[0];
          if (pathParts.length > 2) {
            diagnosis += ", " + pathParts[2];
          }
          if (pathParts.length > 3) {
            operation = pathParts[3];
          }
          if (pathParts.length > 4) {
            operation += ", " + pathParts[4];
          }
         
        });
       
        const response = await saveUserStat(this.editedStat.id, this.statCodes.join(' ; '), this.editedStat.date, this.editedStat.path, diagnosis, operation, this.editedStat.note, this.editedStat.parent_id); 
        console.log(response);
        
        this.$router.go(-1); 
      },

      async saveDiagConfig() {
        // console.log("save diagnostics configuration");
        this.$store.state.config.statResult = this.statPath;

        // console.log(this.editedStat);
      
        // save on backend
        await saveStat(JSON.stringify( this.statItems)); 
        
        this.$router.go(-1); 
      },


      //3d tree
      handleNodeClick(node) {
        console.log(`Node clicked:: ${node.data.name}, id ${node.data.id}, parent ${node.data.parentId}`);
       
        this.selectedItem = node;
        const nodeId = node.data.id;
        this.updateNodeById(this.treeData, nodeId, "Updated Name");

        const pathToRoot = this.getPathToRoot(this.treeData, node);
        // console.log(`Path to root: ${pathToRoot.join(' / ')}`);
        this.statPath = pathToRoot.join(' / ');
      },

   
       // Recursive function to find and update the node name
       updateNodeById(tree, id, newName) {
       
            if (tree.id === id) {
                tree.state = 1; // Update the state if the ID matches
                //tree.name = newName; // Update the name if the ID matches
                //return true; // Exit condition to stop searching
            }
            else
            {
              tree.state = 0;
            }
            // If there are children, check them
            if (tree.children) {
                for (const child of tree.children) {
                  const found = this.updateNodeById(child, id, newName);
                  if (found) {
                    return true; // Stop when found
                  }
                }
            }
            return false; // Return false if the ID was not found in this subtree
        },

        getPathToRoot(tree, node) {
            const path = [];
            this.findPath(tree, node, path);
            // remove root node (Statistika)
            path.pop();
            return path.reverse(); // Reverse the path to get it from root to clicked node
        },

        findPath(tree, targetNode, path) {
            // Check if current node matches the target node
            const targetNodeId = (this.selectedViewType == 'Strom') ? targetNode.id : targetNode.data.id;
            
            // 3D tree
            //if (tree.id === targetNode.data.id) {
            // 2D tree
            if (tree.id === targetNodeId) {
                path.push(tree.name);  // Add the current node's name to the path
                return true; // Indicate that the target node was found
            }

            // If current tree node has children, search them
            if (tree.children) {
                for (const child of tree.children) {
                    if (this.findPath(child, targetNode, path)) {
                        path.push(tree.name); // After finding a matching child, add current node's name
                      //  tree.isOpen = true;
                        return true; // Stop searching when found
                    }
                }
            }
            return false; // Return false if the node was not found in this subtree
        },


        createTree(data) {
          const tree = [];
          const map = {};

          //console.log("create tree");
          // First pass: create a map of nodes
          data.forEach(item => {
              //console.log(item.id + " " + item.code);
              map[item.id] = { ...item, children: [], isOpen: false, level: 0, isSelected: false }; // Spread original properties and add an empty array for children
          });

            // Second pass: organize the hierarchy and set levels
            data.forEach(item => {
                const currentNode = map[item.id];
                if (Number(item.parentId)) {
                    // If there's a parent, push this item into its parent's children array
                    const parentNode = map[item.parentId];
                    currentNode.level = parentNode.level + 1; // Set level based on parent's level
                    parentNode.children.push(currentNode);
                } else {
                    // If no parent, push to the root
                    tree.push(currentNode);
                }
            });

          return tree;
      },

      addNode() {
        if (this.selectedItem) {
          this.dialogMessage = "Zadejte název nové položky";
          this.dialogentryLabel = "Upravit údaj";
          this.dialogResponseValue == "";
          this.dialogShowEdit = true;
          this.dialogDefaultValue = "nová";
          this.dialogResponseCallBack = () => {
          
            var newItem = null;
            console.log("Add node");
            console.log(this.selectedItem.data.id);
            console.log(this.selectedItem.data.parentId);
            var maxIndex = 0;
            if (this.selectedItem) {
              this.statItems.forEach(item => {
                if (item.id > maxIndex) {
                  maxIndex = item.id;
                }
              }
              );
              ++maxIndex;

            //console.log("Max index " + maxIndex);
                newItem = {
                id: maxIndex,
                state: 0,
                code: 'X',
                name: this.dialogResponseValue,
                parentId: this.selectedItem.data.id
              }
            
            this.statItems.push(newItem);
            this.updateTree();
            }
         }
          this.showModal = true;
        }
      },
      
      editNode() {
        console.log("Edit node");
        console.log(this.selectedItem.data.id);
        if (this.selectedItem) {
          this.dialogMessage = "Zadejte název položky";
          this.dialogentryLabel = "Upravit údaj";
          this.dialogDefaultValue = this.selectedItem.data.name;
          this.dialogShowEdit = true;
          
          this.dialogResponseCallBack = () => {
           
            this.statItems.forEach(item => {
              if (item.id == this.selectedItem.data.id) {
                item.name = this.dialogResponseValue;
              }
            });
          this.updateTree();
          }
          this.showModal = true;
        }
      },
    
      deleteNode() {
        console.log("Delete node");
        console.log(this.selectedItem);
        if (this.selectedItem) {

          this.dialogMessage = "Smazat položku " + this.selectedItem.data.name + "?";
          this.dialogShowEdit = false;
          this.dialogDefaultValue = "ok";
          this.dialogResponseCallBack = () => {
           
            const index = this.statItems.findIndex(item => item.id === this.selectedItem.data.id);
            const parentIndex = this.statItems.findIndex(item => item.parentId === this.selectedItem.data.id);
         
            if ((index > -1) && (parentIndex == -1)) {
              this.statItems.splice(index, 1);
            }
            else
            {
              this.dialogMessage = "Existují podpoložky. Nelze smazat.";
              this.dialogShowEdit = false;
              this.showModal = true;
            }
            this.updateTree();
         };
         this.showModal = true;
        }
      },

    // import csv source
    importStat() {
      this.$refs.fileInput.click();
    },
    // import csv source - after file select
    handleFileUpload(event) {
     
    const file = event.target.files[0]; // Get the selected file
    if (file) { 
      const reader = new FileReader();
      reader.onload = async (e) => {
        const fileContent = e.target.result; // File content in plain text
          //console.log('File content:', fileContent);
          var parentLevel = new Array(9);
          var nodeId = 0;
          //var nodes = new Array();
          var newTree = [];
          var nodeInLine = 0;
          var nodeTable = [];
          // Split content by line to get each record
          const records = fileContent.split('\n').map(line => line.trim()).filter(line => line); // Remove empty lines
          console.log('Records:');
          for (var j = 0; j < records.length; j++) {

            nodeTable.push([]);

            console.log(parentLevel[0] + "," + parentLevel[1] + "," + parentLevel[2] + "," + parentLevel[3] + "," + parentLevel[4] + ",") ;
        
            const record = records[j];
            const allFields = record.split(';').map(field => field.trim());

            // Initialize arrays for even and odd fields
            const fields = [];
            const numIds = [];

            // Loop through the fields array
            for (let i = 0; i < allFields.length; i++) {
                if (i % 2 === 0) {
                    // Even index
                    fields.push(allFields[i]);
                } else {
                    // Odd index
                    numIds.push(allFields[i]);
                }
            }

            for (var i = 0; i < fields.length; i++) {
                ++nodeId;
                var parentLevelValue = 0;
                if (i == 0) {
                  nodeInLine = 0;
                }
                else
                {
                    if (nodeInLine > 0) {
                      parentLevelValue = nodeInLine;
                    }
                    else
                    {
                      for (let row = j - 1; row >= -1; row--) {
                         parentLevelValue = nodeTable[row][i-1];   
                         if (parentLevelValue != -1) {
                           break;
                         }
                      }
                    }
                }
               
                if (fields[i].length > 0) {
                  parentLevel[i] = nodeId;
                  console.log("(" + i + ")" + nodeId + ". " + fields[i] + " /" + parentLevelValue);
                  var tLink = 0;
                  var nodeName = fields[i];

                  // tlink flag, csv shows circle, import gets it as " ?"
                  if (nodeName.includes(" ?")) {
                    nodeName = nodeName.replace(" ?", "");
                    tLink = 1;
                  }
                  
                  const node = {
                        id: nodeId,
                        state: 0,
                        code: numIds[i],
                        name: nodeName,
                        parentId: parentLevelValue,
                        tlink: tLink,
                    }
                  newTree.push(node);
                  nodeInLine = nodeId;
                  nodeTable[j].push(nodeId);
                }
                else
                {
                  nodeTable[j].push(-1);
                }
       
            }
            //console.log("line " + j);
            //console.log(nodeTable[j][0] + ";" + nodeTable[j][1] + ";" + nodeTable[j][2] + ";" + nodeTable[j][3] + "-" + nodeTable[j][4]);// + "-" + nodeTable[j][5] + "-" + nodeTable[j][6] + "-" + nodeTable[j][7] + "-" + nodeTable[j][8]);
          }

          console.log('newTree Nodes:');
          console.log(newTree.length);
          for (const node of newTree) { 
               console.log(node.name +  " "  + node.code + " " + node.tlink);
          }
          
          // Process each record
          // const processedRecords = records.map(record => {
          //     const fields = record.split(';').map(field => field.trim()); // Split fields by semicolon and trim whitespace
              
          //     // Check for basic validity (e.g., number of fields, required fields)
          //     if (fields.length < 5) { 
          //         console.error('Invalid record:', record);
          //         return null; // Skip invalid record
          //     }
           
          //     return {
          //       statItem: {
          //         id: fields[0],
          //         state: fields[1],
          //         code: fields[2],
          //         name: fields[3],
          //         parentId: fields[4],
          //       }
          //     };
          // }).filter(record => record !== null); // Remove invalid records

          // var i = 1;
          // //var newTree = [];
          // for (const record of processedRecords) { 
          //   if (record) {
          //     console.log(record.statItem);
          //     //newTree.push(record.statItem);
          //     ++i;
          //   }
          // }

          this.statItems = newTree;
          this.updateTree();
          
          alert("Importováno " + i + " záznamů.");
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };

      reader.readAsText(file);

      }
    },

    editPlan() {

      if (!this.selectedItem) {
        alert("Vyberte položku pro definici terapeutického plánu.");
        return;
      }
      this.$router.push({ name: 'TherapeuticPlanPage', params: { 
        code: this.selectedItem.code,
        stat: this.statPath, 
       } });
    },

    updateTree() {
       this.treeData.children = this.createTree(this.statItems);
       this.listA.length = 0;
       this.listB.length = 0;
       this.listC.length = 0;
       this.listD.length = 0;
       this.listE.length = 0;
       this.fillList(this.listA, 0);
    },

    traverseAndCheckSelection(tree) {
      if (!Array.isArray(tree)) {
        console.error('Expected an array but received:', tree);
        return false; // Return false if tree is not an array
      }

      for (const node of tree) {
        if ((node.isSelected) && (node.level == 3)) {
          // console.log(" found selected - - " + node.name);
          return true; // Return true if a selected node is found
        }
        if (node.children && Array.isArray(node.children)) {
          if (this.traverseAndCheckSelection(node.children)) {
            return true; // Return true if a selected node is found in children
          }
        }
      }

      return false; // Return false if no selected nodes are found
    },

    traverseAndResetSelection(tree) {
      if (!Array.isArray(tree)) {
        console.error('Expected an array but received:', tree);
        return;
      }
      tree.forEach((node) => {
        node.isSelected = false; 
        if (node.children && Array.isArray(node.children)) {
          this.traverseAndResetSelection(node.children); // Recursive call for child nodes
        }
      });
    },

    traverseAndResetState(tree) {
      if (!Array.isArray(tree)) {
        console.error('Expected an array but received:', tree);
        return;
      }
      tree.forEach((node) => {
        node.state = 0; // Set current node state to 0
        if (node.children && Array.isArray(node.children)) {
          this.traverseAndResetState(node.children); // Recursive call for child nodes
        }
      });
    },

    showPathInTree(path) {
      const nodes = path.split(' / ').map((name) => name.trim());
      // enumerate each node and find id
      var lastNode = "";
      for (const nodeName of nodes) {
       console.log(nodeName);
       lastNode = nodeName;
       this.openNodeInTree(this.treeData.children, nodeName);
      }  
      if (lastNode != "") {
         this.selectNodeInTree(this.treeData.children, lastNode);
      }
    },

    getNodeIdFromName(tree, name) {
      if (!Array.isArray(tree)) {
        console.error('Expected an array but received:', tree);
        return;
      }
      for (const node of tree) {
        if (node.name === name) {
          return node.id;
        }
        if (node.children && Array.isArray(node.children)) {
          const id = this.getNodeIdFromName(node.children, name); // Recursive call for child nodes
          if (id) {
            return id;
          }
        }
      }
    },

    openNodeInTree(tree, nodeName) {
      if (!Array.isArray(tree)) {
        console.error('Expected an array but received:', tree);
        return;
      }
      tree.forEach((node) => {
        if (node.name === nodeName) {
          node.isOpen = true;
        }
        if (node.children && Array.isArray(node.children)) {
          this.openNodeInTree(node.children, nodeName); // Recursive call for child nodes
        }
      });
    },

    openNodeInTreeByCode(tree, nodeCode) {
      if (!Array.isArray(tree)) {
        console.error('Expected an array but received:', tree);
        return;
      }
      tree.forEach((node) => {
        if (node.code === nodeCode) {
          node.isOpen = true;
          console.log("opening node... " + node.name);
        }
        if (node.children && Array.isArray(node.children)) {
          this.openNodeInTreeByCode(node.children, nodeCode); // Recursive call for child nodes
        }
      });
    },

    selectNodeInTree(tree, nodeName) {
      if (!Array.isArray(tree)) {
        console.error('Expected an array but received:', tree);
        return;
      }
      tree.forEach((node) => {
        if (node.name === nodeName) {
          //node.state = 1; 
          node.isSelected = true;
          return;
        }
        if (node.children && Array.isArray(node.children)) {
          this.selectNodeInTree(node.children, nodeName); // Recursive call for child nodes
        }
      });
    },

    selectNodeInTreeByCode(tree, nodeCode) {
      if (!Array.isArray(tree)) {
        console.error('Expected an array but received:', tree);
        return;
      }
      tree.forEach((node) => {
        if (node.code === nodeCode) {
          node.isOpen = true;
          node.isSelected = true;
          
          return;
        }
        if (node.children && Array.isArray(node.children)) {
          this.selectNodeInTreeByCode(node.children, nodeCode); // Recursive call for child nodes
        }
      });
    },

  getNodeInTreeByCode(tree, nodeCode) {
    if (!Array.isArray(tree)) {
        console.error('Expected an array but received:', tree);
        return null; // Return null if input is invalid
    }
    
    for (const node of tree) {
        if (node.code === nodeCode) {
            return node; // Return the found node
        }
        if (node.children && Array.isArray(node.children)) {
            const foundNode = this.getNodeInTreeByCode(node.children, nodeCode); // Recursive call
            if (foundNode) {
                return foundNode; // Return the found node from children
            }
        }
    }
    
    return null; // Return null if node not found
  },
 
   

  resetHighlightInTree(tree) {
    if (!Array.isArray(tree)) {
      return;
    }
    tree.forEach((node) => {
      node.state = 0;
      if (node.children && Array.isArray(node.children)) {
        this.resetHighlightInTree(node.children);
      }
    });
  },  

  openPath(tree, targetNode) {
            // Check if current node matches the target node
            const targetNodeId = (this.selectedViewType == 'Strom') ? targetNode.id : targetNode.data.id;
            
            // 3D tree
            //if (tree.id === targetNode.data.id) {
            // 2D tree
            if (tree.id === targetNodeId) {
                //path.push(tree.name);  // Add the current node's name to the path
                return true; // Indicate that the target node was found
            }

            // If current tree node has children, search them
            if (tree.children) {
                for (const child of tree.children) {
                    if (this.openPath(child, targetNode)) {
                        //path.push(tree.name); // After finding a matching child, add current node's name
                        tree.isOpen = true;
                        return true; // Stop searching when found
                    }
                }
            }
            return false; // Return false if the node was not found in this subtree
  },
       


  searchNodeInTree(tree, nodeName) {
    if (!Array.isArray(tree)) {
      console.error('Expected an array but received:', tree);
      return;
    }
    tree.forEach((node) => {
      if (node.name.toLowerCase().includes(nodeName)) {
        
        //console.log(`-*-> Selected node: ${node.name}  ${node.parentId}`);

        node.state = 1; // Update the state if the ID matches
    
        this.openPath(this.treeData, node);
        
        return;
      }
      if (node.children && Array.isArray(node.children)) {
        this.searchNodeInTree(node.children, nodeName); // Recursive call for child nodes
      }
    });
  }, 

  collapseTreeAll() {
    this.collapseTree(this.treeData.children);
  },
  collapseTree(tree) {
    if (!Array.isArray(tree)) {
      console.error('Expected an array but received:', tree);
      return;
    }
    tree.forEach((node) => {
      node.isOpen = false; 
      if (node.children && Array.isArray(node.children)) {
        this.collapseTree(node.children); // Recursive call for child nodes
      }
    });
  }, 
  handleKeydown(event) {
      if (event.key === 'F2') {
        if (this.selectedItem.level > 2) {
          event.preventDefault();
          this.toggleSelection();
        }
      }
  },

},

  watch: {
    selectedViewType(newValue) {
      //console.log(`Selected view type changed to: ${newValue}`);
      this.editEnabled = (newValue === 'Graf');
    },
    searchTerm(newValue) {
     // console.log(`Search: ${newValue}`);
     
     this.resetHighlightInTree(this.treeData.children);
     if (newValue.length === 0) {
        return;
     }
     this.collapseTreeAll();
     this.searchNodeInTree(this.treeData.children, newValue.toLowerCase());
    },
    // valueA(newValue) {
    //   this.fillList(this.listB, newValue);
    //   this.listC.length = 0;
    //   this.listD.length = 0;
    //   this.listE.length = 0;
    //   const selectedA = this.listA.find(item => item.id === newValue);
    //   this.statPath = (selectedA ? selectedA.name : 'Unknown');
    // },
    // valueB(newValue) {
    //   this.fillList(this.listC, newValue);
    //   this.listD.length = 0;
    //   this.listE.length = 0;
    //   const selectedA = this.listA.find(item => item.id === this.valueA);
    //   const selectedB = this.listB.find(item => item.id === newValue);
    //   this.statPath = (selectedA ? selectedA.name : 'Unknown') + " / " + (selectedB ? selectedB.name : 'Unknown');
    // },
    // valueC(newValue) {
    //   this.fillList(this.listD, newValue);
    //   this.listE.length = 0;
    //   const selectedA = this.listA.find(item => item.id === this.valueA);
    //   const selectedB = this.listB.find(item => item.id === this.valueB);
    //   const selectedC = this.listC.find(item => item.id === newValue);
    //   this.statPath = (selectedA ? selectedA.name : 'Unknown') + " / " + (selectedB ? selectedB.name : 'Unknown') + " / " + (selectedC ? selectedC.name : 'Unknown');
    // },
    // valueD(newValue) {
    //   this.fillList(this.listE, newValue);
    //   const selectedA = this.listA.find(item => item.id === this.valueA);
    //   const selectedB = this.listB.find(item => item.id === this.valueB);
    //   const selectedC = this.listC.find(item => item.id === this.valueC);
    //   const selectedD = this.listD.find(item => item.id === newValue);
    //   this.statPath = (selectedA ? selectedA.name : 'Unknown') + " / " 
    //    + (selectedB ? selectedB.name : 'Unknown') + " / " 
    //    + (selectedC ? selectedC.name : 'Unknown') + " / " 
    //    + (selectedC ? selectedD.name : 'Unknown');
    // },
    // valueE(newValue) {
    //   const selectedA = this.listA.find(item => item.id === this.valueA);
    //   const selectedB = this.listB.find(item => item.id === this.valueB);
    //   const selectedC = this.listC.find(item => item.id === this.valueC);
    //   const selectedD = this.listD.find(item => item.id === this.valueD);
    //   const selectedE = this.listE.find(item => item.id === newValue);
    //   this.statPath = (selectedA ? selectedA.name : 'Unknown') + " / " 
    //    + (selectedB ? selectedB.name : 'Unknown') + " / " 
    //    + (selectedB ? selectedC.name : 'Unknown') + " / " 
    //    + (selectedC ? selectedD.name : 'Unknown') + " / " 
    //    + (selectedE ? selectedE.name : 'Unknown');
    // }
  },
  created() {
      if (this.$route.params.patient) {
        this.patient = JSON.parse(this.$route.params.patient);
        this.editedStat = JSON.parse(this.$route.params.stat);
        console.log("loading - created");
        console.log(this.editedStat.id);
        console.log(this.editedStat.code);

       
        // if (this.$store.state.rights == 1) {
        //   // set rights for user
        // }

        this.userName = this.patient.name + " " + this.patient.surname;

        if ((this.editedStat.path) && (this.editedStat.path.length > 0)) {
           this.statPath = this.editedStat.path;
           const stats = this.editedStat.path.split(';').map(field => field.trim());
            for (var i = 0; i < stats.length; i++) {
              this.statPaths.push(stats[i]);
            }
           //console.log("Stat path: " + this.editedStat.path);
        }

        if ((this.editedStat.code) && (this.editedStat.code.length > 0)) {
           this.statCodes = this.editedStat.code.split(';').map(field => field.trim());
           //console.log("Codes path: " + this.statCodes);
        }

      }

      this.loadDiagnostics();
    },

    mounted() {
    on('node-clicked', (node) => {
      if (this.treeData.children.length === 0) {
        //console.error('Tree data is empty');
        return;
      }
      console.log('TreeNode clicked:', node);
      this.selectedItem = node;
      this.traverseAndResetState(this.treeData.children);
      node.state = 1; // Update the state if the ID matches
      const pathToRoot = this.getPathToRoot(this.treeData, node);
      // console.log(`Path to root: ${pathToRoot.join(' / ')}`);
      this.statPath = pathToRoot.join(' / ');
      
      this.therapeuticPlanEnabled = (this.selectedItem.level < 3) ? false : true; 
    });
    window.addEventListener('keydown', this.handleKeydown);
   },
   beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
    
  };
  
  </script>

<style scoped>
.select-spacing {
  margin-bottom: 15px; /* Adjust the value for desired spacing */
}

.select-spacing {
  margin-bottom: 15px; /* Adjust the value for desired spacing */
}

.floating-button1 {
  position: fixed; /* Keeps the button fixed in the viewport */
  bottom: 30px; /* Distance from the bottom */
  right: 30px; /* Distance from the right */
  z-index: 1000; /* Ensures it appears on top of other elements */
  border-radius: 50%; /* Circular shape */
  width: 60px; /* Width of the button */
  height: 60px; /* Height of the button */
  display: flex; /* Center the icon */
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.floating-button2 {
  position: fixed; /* Keeps the button fixed in the viewport */
  bottom: 120px; /* Distance from the bottom */
  right: 30px; /* Distance from the right */
  z-index: 1000; /* Ensures it appears on top of other elements */
  border-radius: 50%; /* Circular shape */
  width: 60px; /* Width of the button */
  height: 60px; /* Height of the button */
  display: flex; /* Center the icon */
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.floating-button3 {
  position: fixed; /* Keeps the button fixed in the viewport */
  bottom: 210px; /* Distance from the bottom */
  right: 30px; /* Distance from the right */
  z-index: 1000; /* Ensures it appears on top of other elements */
  border-radius: 50%; /* Circular shape */
  width: 60px; /* Width of the button */
  height: 60px; /* Height of the button */
  display: flex; /* Center the icon */
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.floating-button4 {
  position: fixed; /* Keeps the button fixed in the viewport */
  bottom: 300px; /* Distance from the bottom */
  right: 30px; /* Distance from the right */
  z-index: 1000; /* Ensures it appears on top of other elements */
  border-radius: 50%; /* Circular shape */
  width: 60px; /* Width of the button */
  height: 60px; /* Height of the button */
  display: flex; /* Center the icon */
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for better visibility */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.floating-button:hover {
  background-color: #007bff; /* Change color on hover */
}
</style>