<template>
  <div>
    
    <div ref="pdfContent">
    
    <div class="content-wrapper">

    <div class="page" id="page-1">  
    
    <table class="full-width-table">
      <tr>
        <td>IC Klinika Brno s.r.o 
          <br>
          Bratří Čapků 14
          <br>
          602 00 Brno
        </td>
        <td><img :src="logo" alt="Logo" class="navbar-image"></td>
        <td>
          Provozovna:
          <br>
          IC Klinika Brno
          <br>
          Bulharská 29, 612 00 Brno
        </td>
      </tr>
      <tr>
        <td></td>
        <td><h1>Terapeutický plán</h1></td>
        <td></td>
      </tr>
    </table> 

    <table class="full-width-table-framed">
      <tr>
        <td class="left-align">Jméno, příjmení a titul pacienta: 
          <br>
          <input type="text" v-model="fullName" 
          class="input-no-border" 
          placeholder="Jméno, příjmení a titul" readonly />
        </td>
        <td class="left-align">Rodné číslo/číslo pojištěnce (cizinci):
          <br>
          <input type="text" v-model="patient.pid" 
          class="input-no-border" 
          placeholder="Rodné číslo/číslo pojištěnce" readonly />
        </td>
      </tr>
     
     
    </table> 

    <br>

    <!-- <h3>{{statText}}</h3>
    <br> -->
  
    <div class="left-align" v-html="thPlanContent"></div>
          
  </div>  

  <br>
   
    <table class="footer-table">
      <tr>
        <td>
          &#9993; info@icklinika.cz
          <br>
          &#9742; +420 541 420 770-1
        </td>
        <td>
          &#127758; https://www.icklinika.cz
          <br>
          IČO: 268 85 361
        </td>
      </tr> 
    </table> 

  <hr>

  <table class="footer-table">
      <tr>
        <td>
          <b-button
            variant="secondary"
            @click="saveFormPDF"
            >Uložit jako PDF</b-button
            >
        </td>
        <td>
          <b-button
            variant="success"
            @click="saveForm"
            >Uložit formulář</b-button
            >
        </td>

      </tr> 
    </table> 

  </div>
</div>
</div>
</template>
  
  <script>
  import { saveIcUser, saveIcFile, getThPlan, saveUserStatMeta, getUserStatMeta } from '../services/api'; // Adjust import based on your file structure
  import html2pdf from 'html2pdf.js';
  
  export default {
    name: 'StatDocumentPage',
   
    data() {
    return {
      user: null,
      stat: null,
      patient: null,
      userName: "",
      logo: require('@/assets/logo.png'),
      printView: false,
      // imgPatientSrc: null,
      // imgStaffSrc: null,
      // signatureDialog: false,
      // collectPatientSignature: true,
      statPath: "",
      statCode: "",
      statText: "",
      thPlanContent: "",
      formValues: [],
      };
  },
  computed: {
  fullName: {
    get() {
      let fullName = `${this.patient.name} ${this.patient.surname}`.trim();

      // Add a comma and title if the title is not empty or null
      if (this.patient.title) {
        fullName += fullName ? `, ${this.patient.title}` : this.patient.title;
      }
      return fullName;
    },
    set(value) {
      const names = value.split(' ');
      this.patient.name = names.slice(0, -1).join(' '); // Everything except the last part
      this.patient.surname = names[names.length - 1]; // Last part as surname
    }
   }
  },
  methods: {
   
    togglePassInfo(event) {
      // Set passinfo to the opposite value of the second checkbox
      this.patient.passinfo = !event.target.checked; // Invert the value
    },
    async saveFormPDF() {
      this.printView = true;
      console.log(this.user);
      const response = await saveIcUser(this.patient); 
      console.log(response);

      var newName = "statdoc-" + this.stat.date + ".pdf";

      const responseSaveFile = await saveIcFile(this.patient.id, this.removeExtension(newName), newName, "soubor");
      console.log(responseSaveFile);

      await this.generatePdf(newName);
      this.$router.go(-1);
    
    },

    async saveForm() {
      
      var form_elem = document.getElementsByClassName('ick-checkbox');
      //console.log(form_elem);
      for (let i = 0; i < form_elem.length; i++) {
        this.formValues.push( {class:form_elem[i].className, id:form_elem[i].id, value: form_elem[i].checked ? '1' : '0'});
      }
      form_elem = document.getElementsByClassName('ick-editbox');
      for (let i = 0; i < form_elem.length; i++) {
        this.formValues.push( {class:form_elem[i].className, id:form_elem[i].id, value: form_elem[i].value});
      }
      
      const response = await saveUserStatMeta(this.stat.id, JSON.stringify(this.formValues)); 
      console.log(response);
      this.$router.go(-1);
    },

    async generatePdf(fileName) {
      const element = this.$refs.pdfContent;

      const pdfOptions = {
        margin: [0, 0, 0, 0],
        filename: fileName,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
      };

      // Generate PDF and get as a Blob
      html2pdf()
        .from(element)
        .set(pdfOptions)
        .outputPdf('blob')
        .then(blob => {
          this.uploadPdf(fileName, blob);
        });
    },
   
    async uploadPdf(fileName, blob) {

      const formData = new FormData();
      formData.append('file', blob, fileName);
      formData.append('pid', this.patient.pid);

      this.axios.post('https://ickapp.cz/backend/server/uploadfile.php', formData,
      {
        headers: {
        'Content-Type': 'multipart/form-data'
        }
      }
      ).then(function(data){
        console.log(data.data);
      })
      .catch(function(){
        console.log('FAILURE!!');
      });
    
    },

    removeExtension(filename) {
      const lastDotIndex = filename.lastIndexOf('.');
      if (lastDotIndex === -1 || lastDotIndex === 0) return filename;
      return filename.substring(0, lastDotIndex);
    },
       
    async loadThPlan() {

      if (this.statCode == null) return;

      const plans = this.statCode.split(';'); // statPath
      var planHtmlText = "";

      //console.log(this.statPlan);
      for (let i = 0; i < plans.length; i++) {
        //planHtmlText = planHtmlText + "<br><h3>" + plans[i] + "</h3>";

        //console.log("xxxxxxx - plans ");
        //console.log(plans[i].trim());
        
        const response = await getThPlan(plans[i].trim());

       // console.log(response);

        // Check if the response data is in the expected format
        //if (response.data && response.data.data && typeof response.data.data === 'string') {
        if (response.data && response.data.data) {
         
            const title = response.data.data.stat;
            const content = response.data.data.content;

            //console.log(content);
            
            planHtmlText = planHtmlText + "<br><h3>" + title + "</h3>";

            // Initialize thPlanContent as the response content
            planHtmlText = planHtmlText + content;

            // _checkbox(caption, value)
            const checkboxRegex = /_checkbox\(([^,]+),\s*([^)]+)\)/g;
            planHtmlText = planHtmlText.replace(
                checkboxRegex,
                (match, caption, value) => {
                    const isChecked = value.trim() === '1'; // Check if value is '1'
                    return `<label><input type="checkbox" class="ick-checkbox" id="${caption} value="${value}" ${isChecked ? 'checked' : ''}> ${caption}</label>`;
                }
            );

            // _editbox(label, value)
            const editboxRegex = /_editbox\(([^,]+),\s*([^)]+)\)/g;
            planHtmlText = planHtmlText.replace(
                editboxRegex,
                (match, label, value) => {
                  return `<label>${label}: <input type="text" class="ick-editbox" id="${label} value="${value}"></label>`;
                }
            );

            // _img(align, width) 
            const imgRegex = /_img\((right|left), (\d+)\)(.*?)<img([^>]*?)>/g;
            planHtmlText = planHtmlText.replace(imgRegex, (match, floatDirection, widthValue, p1, p2) => {
                return `${p1}<img style="width:${widthValue}px; height:auto; float:${floatDirection}; margin: 0 10px 10px 0;"${p2}>`;
            });
  
        // }
        // else {
        //   // Handle the case where the data is not in the expected format
        //   console.error("Unexpected response format:", response);
        //  // planHtmlText = ''; // or set to a default value
        }
    
  }

  this.thPlanContent = planHtmlText;

  // fill form values
  //console.log("form values");
  const res = await getUserStatMeta(this.stat.id);
  //console.log(res.data.data);

    if (res.data && res.data.data && typeof res.data.data === 'string') {
      const savedValues = JSON.parse(res.data.data);

      //console.log(savedValues);

      savedValues.forEach(item => {
          const element = document.getElementById(item.id);

          if (element) {
              // Check the class to determine the type
              if (item.class === 'ick-checkbox') {
                  // For checkboxes, set checked property
                  element.checked = item.value === '1';
              } else if (item.class === 'ick-editbox') {
                  // For editboxes, set value
                  element.value = item.value;
              }
          }
      });
    }


    // const response = await getThPlan(this.statPlan);

    // // Check if the response data is in the expected format
    // if (response.data && response.data.data && typeof response.data.data === 'string') {
    //     // Initialize thPlanContent as the response content
    //     this.thPlanContent = response.data.data;

    //     // _checkbox(caption, value)
    //     const checkboxRegex = /_checkbox\(([^,]+),\s*([^)]+)\)/g;
    //     this.thPlanContent = this.thPlanContent.replace(
    //         checkboxRegex,
    //         (match, caption, value) => {
    //             const isChecked = value.trim() === '1'; // Check if value is '1'
    //             return `<label><input type="checkbox" class="ick-checkbox" id="${caption} value="${value}" ${isChecked ? 'checked' : ''}> ${caption}</label>`;
    //         }
    //     );

    //     // _editbox(label, value)
    //     const editboxRegex = /_editbox\(([^,]+),\s*([^)]+)\)/g;
    //     this.thPlanContent = this.thPlanContent.replace(
    //         editboxRegex,
    //         (match, label, value) => {
    //           return `<label>${label}: <input type="text" class="ick-editbox" id="${label} value="${value}"></label>`;
    //         }
    //     );

    //     // _img(align, width) 
    //     const imgRegex = /_img\((right|left), (\d+)\)(.*?)<img([^>]*?)>/g;
    //     this.thPlanContent = this.thPlanContent.replace(imgRegex, (match, floatDirection, widthValue, p1, p2) => {
    //         return `${p1}<img style="width:${widthValue}px; height:auto; float:${floatDirection}; margin: 0 10px 10px 0;"${p2}>`;
    //     });

    //     //console.log("this.thPlanContent");
    //     //console.log(this.thPlanContent);

    //     // fill form values
    //     //console.log("form values");
    //     const res = await getUserStatMeta(this.stat.id);
    //     //console.log(res.data.data);

    //     if (res.data && res.data.data && typeof res.data.data === 'string') {
    //       const savedValues = JSON.parse(res.data.data);

    //       //console.log(savedValues);

    //       savedValues.forEach(item => {
    //           const element = document.getElementById(item.id);

    //           if (element) {
    //               // Check the class to determine the type
    //               if (item.class === 'ick-checkbox') {
    //                   // For checkboxes, set checked property
    //                   element.checked = item.value === '1';
    //               } else if (item.class === 'ick-editbox') {
    //                   // For editboxes, set value
    //                   element.value = item.value;
    //               }
    //           }
    //       });
    //     }
       
    // } else {
    //     // Handle the case where the data is not in the expected format
    //     console.error("Unexpected response format:", response);
    //     this.thPlanContent = ''; // or set to a default value
    // }
  },

  },

  created() {
    if (this.$route.params.patient) {
    this.patient = JSON.parse(this.$route.params.patient);
    
    //console.log(this.patient.passinfo);
    //console.log(this.$store.state.rights);
   
    if (this.$route.params.stat) {
      this.stat = JSON.parse(this.$route.params.stat);
      console.log(this.stat);
      this.statPath = this.stat.path;
      this.statCode = this.stat.code;
      this.statText = this.statPath;
      this.loadThPlan();
    }
  }
 },
};
 
</script>

<style>

.signature-image {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.full-width-table-sign td {
  padding: 5px;
}

.navbar-image {
  height: 40px; /* Set the desired height of the image */
  margin-right: 15px; /* Adds space between the image and the nav items */
}

.content-wrapper {
  width: 90%;
  margin: 0 auto; /* Centers the div */
}

.full-width-table {
  width: 100%; /* Make the table full width */
  border-collapse: collapse; /* Optional: To remove spaces between cells */
}

.full-width-table td {
  padding: 8px; /* Optional: Adds some padding inside cells */
}

.full-width-table-framed {
  width: 100%; /* Make the table full width */
  border-collapse: collapse; /* Optional: To remove spaces between cells */
}

.full-width-table-framed td {
  border: 1px solid #848484;  
  padding: 8px; /* Optional: Adds some padding inside cells */
}

.left-align {
  text-align: left;
}

.no-border {
  border: none;
  outline: none; /* Optional: Remove the outline on focus */
  width: 100%; /* Optional: Make the input take full width */
  padding: 5px;   /* Optional: Adjust padding */
}

.checkbox-label {
  margin-bottom: 10px; /* Adjust the value as needed for spacing */
  margin-right: 20px;
}

.full-width-table-sign {
    width: 100%; /* Ensure the table takes full width */
    table-layout: fixed; /* Use fixed layout */
    border: 1px solid black; /* Optional: border for visual clarity */
  }
.full-width-table-sign td {
    height: 120px;  
    width: 33.33%; /* Equal width for each column */
    border: 1px solid #ddd;  
    text-align: center; 
    vertical-align: middle; 
    padding: 10px; 
  }
 .full-width-table-sign2 {
    width: 100%; /* Ensure the table takes full width */
    table-layout: fixed; /* Use fixed layout */
 }
.full-width-table-sign2 td {
  width: 33.33%; /* Equal width for each column */
  text-align: center;
  padding: 10px; /* Optional: add some padding */
 }

 .footer-table {
    width: 100%; /* Ensure the table takes full width */
    table-layout: fixed; /* Use fixed layout */
 }

 .page {
  page-break-after: always; /* Ensures each div is treated as a new page */
  padding: 20px; /* Add padding as needed */
}

h1 {
  font-size: 26px; 
}
p {
  font-size: 14px;
}
.input-border {
  border: 1px solid lightgrey; /* Light grey border */
  border-radius: 4px; /* Add slight rounding to the corners */
  padding: 10px; /* Add padding for better text positioning */
  width: 100%; /* Make it responsive */
  box-sizing: border-box; /* Ensure padding and border are included in width */
  transition: border-color 0.2s; /* Smooth transition for focus */
}

.input-no-border {
  border: 1px solid white; /* Light grey border */
  border-radius: 4px; /* Add slight rounding to the corners */
  padding: 10px; /* Add padding for better text positioning */
  width: 100%; /* Make it responsive */
  box-sizing: border-box; /* Ensure padding and border are included in width */
  transition: border-color 0.2s; /* Smooth transition for focus */
}

.input-border:focus {
  border-color: #007BFF; /* Change border color on focus (optional) */
  outline: none; /* Remove default outline on focus */
}

.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
.ql-align-right {
  text-align: right;
}
.ql-size-large {
  font-size: 20px; 
}
.ql-size-huge {
  font-size: 30px; 
}

</style>