<template>
    <div
      class="toast-container position-fixed bottom-0 end-0 p-3"
      aria-live="polite"
      aria-atomic="true"
    >
      <div
        class="toast"
        :class="{ show: showToast }"
        role="alert"
      
      >
        <div class="toast-header">
          <strong class="me-auto">{{ title }}</strong>
          <button type="button" class="btn-close" @click="hideToast"></button>
        </div>
        <div class="toast-body">
          {{ message }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showToast: false,
        message: '',
        title: '',
      };
    },
    methods: {
      show(title, message) {
     
        this.message = message;
        this.showToast = true;
        this.title = title;
  
        // Automatically hide the toast after 3 seconds
        setTimeout(() => {
          this.hideToast();
        }, 3000);
      },
      hideToast() {
        this.showToast = false;
      },
    },
  };
  </script>
  
  <style>
  .toast {
    transition: opacity 0.5s linear;
  }
  .toast.show {
    display: block;
  }
  /* Custom styles for the toast body */
.toast-header {
  background-color: #f2e1e2; /* Change to your desired color */
  color: #721c24; /* Text color for better contrast */
}
.toast-body {
  background-color: #f8d7da; /* Change to your desired color */
  color: #721c24; /* Text color for better contrast */
}
  </style>