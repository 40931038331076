Copy
<template>
  <div>

    <ToastComponent ref="toast" />


    <NavBar
      v-bind:items="[
        {
          text:  navigTitleWithCount,
          to: { name: 'home' },
        },
      ]"
    />
    
    <div class="content-wrapper">

    <div class="container mt-4">

      <div class="button-container d-flex justify-content-end mt-3">
        <div v-if="!$store.state.dirHandle">
          <b-button
            variant="danger"
            @click="loadLocalFolders"
            class="float-right m-2"
            >Vybrat složku data</b-button
          >
        </div>   
      
      </div>

      <div class="search-container mb-3 d-flex">
        <input
            type="text"
            v-model="searchTerm"
            placeholder="Hledej jméno nebo rodné číslo..."
            class="form-control me-2" 
        />
        <b-button
            variant="success"
            @click="addUser"
          >
          <i class="bi bi-person-plus"></i>
        </b-button>
    </div>
     
      <div>
        <!-- <h2 class="mt-4">Items</h2> -->

        <b-table
          :items="filteredItems"
          :fields="fields"
          :per-page="itemsPerPage"
          v-model:current-page="currentPage"
          v-model:sort-by="sortBy"
          v-model:sort-desc="sortDesc"
          @row-clicked="rowClicked"
          class="text-left" 
        >
          <template #cell(id)="data">
            {{ data.item.id }}
          </template>
          <template #cell(address)="data">
            {{ data.item.address }}, {{ data.item.city }} {{ data.item.postcode }}
          </template>
          <template #cell(pid)="data">
            {{ data.item.pid }}
          </template>
          <!-- <template #cell(passinfo)="data">
            <span v-if="data.item.passinfo == 1" class="icon-1">✔️</span>
            <span v-else class="icon-0">❌</span>
          </template> -->
          <template #cell(passinfo)="data">
            <span v-if="data.item.passinfo == 1" class="icon-1">
                <i class="bi bi-check-circle" style="color: green;"></i>
            </span>
            <span v-else class="icon-0">
                <i class="bi bi-x-circle" style="color: red;"></i>
            </span>
        </template>
        </b-table>

        <!-- Pagination Controls -->
        <b-pagination
          v-model="currentPage"
          :total-rows="filteredItems.length"
          :per-page="itemsPerPage"
          aria-label="Pagination"
        ></b-pagination>
      </div>
    </div>
    <div v-if="showProgress">
        <progress :value="processedRecords" :max="totalRecords"></progress>
        <p>{{ processedRecords }} out of {{ totalRecords }} records processed.</p>
    </div>
  </div>

  <!-- <h1>Image Display</h1>
  <img id="image" alt="Image" style="max-width: 100%;" />
  <iframe
      v-bind:src="pdfUrl"
      width="100%"
      height="1000px"
      style="border: none;"
    ></iframe> -->

  
</div>
</template>

<script>
import NavBar from "../components/navbar.vue";
import ToastComponent from '../components/ToastComponent.vue';
import { setBaseURL, getPeople, getFiles, saveIcFile, syncIcUser } from '../services/api'; // Adjust import based on your file structure
import { mapState } from 'vuex';

export default {
  name: 'HomePage',
  components: { NavBar, ToastComponent, },
  data() {
    return {
      logo: require('@/assets/logo.png'),
      items: [],
      currentPage: 1,
      itemsPerPage: 20, 
      sortBy: 'fullname', // Default sorting key; can be changed to 'name' to sort by name initially
      sortDesc: false, // Sort order, `false` for ascending
      fields: [
        { key: 'fullname', label: 'Jméno', sortable: true },
        { key: 'pid', label: 'R.Č.', sortable: true },
        { key: 'address', label: 'Adresa', sortable: true },
        { key: 'hi', label: 'Pojišťovna', sortable: true },
        { key: 'phone', label: 'Telefon', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'passinfo', label: 'Souhlas', sortable: true },
       // { key: 'infperson1', label: 'osoba 1', sortable: true },
       // { key: 'infperson2', label: 'osoba 2', sortable: true },
       // { key: 'approvedate', label: 'datum', sortable: true },
      ],
      showProgress: false,
      totalRecords: 100,
      processedRecords: 0,
      searchTerm: '', // Add this line for search input
      pdfUrl: "",
      binaryData: null,
      navigTitle: "Seznam pacientů",
      
    };
  },
  methods: {
  
    async getFiles() {
      try {
        //const resource = "01_100.pdf";//"01_102.jpg";
        const resource = "01_102.jpg";
        const response = await getFiles(resource);
       // console.log(response.data);
        const blob = new Blob([response.data], { type: 'image/jpeg' });
        //const blob = new Blob([response.data]);
        const imageUrl = URL.createObjectURL(blob);
    //  const imageUrl = URL.createObjectURL(response.data);
        //console.log(blob);
        console.log(imageUrl);

        var ur = "http://www.ickapp.cz/ickdoc/01_102.jpg";

        // Set the blob URL as the image source
       document.getElementById('image').src = ur;
       this.pdfUrl = imageUrl;

       console.log(document.getElementById('image').src);

    
       
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    },

    async fetchItems() {

      // check pc doktor import
      if (this.$store.state.dirHandle) {  
          //try {
            var updatedCount = 0;
            var addedCount = 0;
            const userFolderHandle = this.$store.state.dirHandle; // Make sure to use the correct directory handle
            var exportFolderHandle = await userFolderHandle.getDirectoryHandle("export", { create: true });
            //const fileNames = [];
            for await (const entry of exportFolderHandle.values()) {
                if (entry.kind === 'file') {
                    //fileNames.push(entry.name); 
                    //console.log(entry.name);
                    if (entry.kind === 'file' && entry.name.startsWith("import") && entry.name.endsWith(".txt")) {
                       const file = await entry.getFile(); // Get the file handle
                       const text = await file.text(); // Read the content as text
                       const fields = text.split(';');
                       console.log(fields.length);
                       if (fields.length > 9) {
                          const user = {
                            "surname": fields[0],
                            "name": fields[1],
                            "pid": fields[2],
                            "address": fields[3],
                            "city": fields[4],
                            "hi": fields[5],
                            "phone": fields[6],
                            "email": fields[7],
                            "postcode": "",
                            "title": "",
                          };

                          const response = await syncIcUser(user);
                          // console.log("sync user");
                          //console.log(response);
                          if (response.data.data == "update") ++updatedCount;
                          if (response.data.data == "new") ++addedCount;
                       }

                      
                    }
                }
            }

             // Delete files after processing
             for await (const entry of exportFolderHandle.values()) {
                if (entry.kind === 'file' && entry.name.startsWith("import") && entry.name.endsWith(".txt")) {
                    await exportFolderHandle.removeEntry(entry.name); // Delete the file
                    //console.log(`Deleted file: ${entry.name}`);
                }
            }

            if (updatedCount > 0 || addedCount > 0) {
              this.$refs.toast.show("Upozornění", `Synchronizace dokončena. Přidáno: ${addedCount}, aktualizováno: ${updatedCount}`);
            }

      }


      this.$store.state.config.selectedDocumentTab = 0;
      try {
        if (this.$store.state.config.patientListFilter)
          this.searchTerm = this.$store.state.config.patientListFilter;
        const response = await getPeople();
        console.log("get people");
        console.log(response);
        this.items = response.data.data; // Adjust based on your API response structure
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    },


    saveFile() {
      //console.log("0s - click");
      this.$refs.fileInput2.click();
    },
    

    rowClicked(item) {
      console.log(item.id);
      this.$store.state.config.patientListFilter = this.searchTerm;
      console.log(this.$store.state.config.patientListFilter);
      this.$router.push({ name: 'UserDetail', params: { id: item.id } });
    },

    addUser() {
      this.$store.state.config.patientListFilter = this.searchTerm;
      this.$router.push({ name: 'UserDetail', params: { id: -1 } });
    },

    async handleFileUpload2(event) {
    
      const file = event.target.files[0];
      if (file) { 

        const response = await saveIcFile(1, this.removeExtension(file.name), file.name, "soubor");
        console.log("1s");
        //var x =  this.$store.state.account.id;   
        //console.log(x);

        console.log(response);

        // upload file , store.state.config.serverUrl;

        let formData = new FormData();
        formData.append('file', file);

        this.axios.post('https://ickapp.cz/backend/server/uploadfile.php', formData,
        {
          headers: {
          'Content-Type': 'multipart/form-data'
          }
        }
        ).then(function(data){
            console.log(data.data);
        })
        .catch(function(){
          console.log('FAILURE!!');
        });
    }

    else {
      alert("Please select a valid file.");
    }
    },

    onChangeFileUpload(){
      this.file = this.$refs.file.files[0];
    },

    removeExtension(filename) {
      const lastDotIndex = filename.lastIndexOf('.');
      if (lastDotIndex === -1 || lastDotIndex === 0) return filename;
      return filename.substring(0, lastDotIndex);
    },

    async loadLocalFolders() {
          try {
              // Ensure this method is called in response to a user action
              this.$store.state.dirHandle = await window.showDirectoryPicker();
             // this.getLocalFolders();
              // Optionally, you can handle the selected directory here
              console.log("Directory selected:", this.$store.state.dirHandle);
          } catch (error) {
              if (error.name === 'AbortError') {
                  console.log("User aborted the directory picker.");
              } else {
                  console.error("Error selecting directory:", error);
              }
          }
      },


  },
  computed: {
    ...mapState(['config', 'account']),
    filteredItems() {
    return this.items.filter(item => {
      // Check if the item's name or PID includes the search term (case insensitive)
      const nameMatch = item.fullname.toLowerCase().includes(this.searchTerm.toLowerCase());
      //const nameMatch = item.fullname.includes(this.searchTerm.toLowerCase());
      const pidMatch = item.pid.toString().includes(this.searchTerm);
      return nameMatch || pidMatch;
    });
    },
    navigTitleWithCount() {
      return `${this.navigTitle} (${this.filteredItems.length} / ${this.items.length})`;
    }
  },
  created() {
    setBaseURL(this.config.serverUrl);
  },
  mounted() {
    this.fetchItems();
    
  },
};

</script>

<style>
.navbar-left {
  display: flex; /* Aligns the image and links horizontally */
  align-items: center; /* Centers items vertically */
}

.navbar-image {
  height: 40px; /* Set the desired height of the image */
  margin-right: 15px; /* Adds space between the image and the nav items */
}

.content {
  margin-top: 56px; /* Adjust margin for the content to avoid overlap with the fixed navbar */
}

.content-wrapper {
  width: 80%;
  margin: 0 auto; /* Centers the div */
}

.text-left {
  text-align: left;
}

.text-nav {
  padding: 10px; 
  font-size: 20px;
  display: flex; /* Use flexbox for alignment */
  align-items: center; 
}
.text-nav-active {
  padding: 10px; 
  font-size: 20px;
  font-weight: bold; /* Set text to bold */
  display: flex; /* Use flexbox for alignment */
  align-items: center; 
}
</style>