<template>
  <div>
    <div class="content-wrapper">
      <table class="full-width-table">
        <tr>
          <td>
            <h1>Terapeutický plán - konfigurace</h1>
          </td>
        </tr>
        <tr>
          <td>
            <h3>{{ statText }}</h3>
          </td>
        </tr>
      </table>

      <!-- <table class="full-width-table-framed">
        <tr>
          <td colspan="2" class="left-align">
            Obsah plánu:
            <br />
            <input
              type="text"
              v-model="thPlanContent"
              :class="input-no-border"
              placeholder="obsah"
            />
          </td>
        </tr>
      </table> -->

      <quill-editor
        id="quillEditor"
        v-model:value="qContent"
        contentType="html"
        :options="editorState.options"
        :disabled="editorState.disabled"
        @blur="onEditorBlur"
        @focus="onEditorFocus"
        @ready="onEditorReady"
        @change="onEditorChange"
      />
    
      <br />

      <table class="footer-table">
        <tr>
          <td>
            <b-button variant="success" @click="saveQuill">Uložit</b-button>
          </td>
          <td>
            <b-button variant="secondary" @click="cancelAction">Storno</b-button>
          </td>
          <td>
            <b-button variant="secondary" @click="addEditField">Vložit editační pole</b-button>
          </td>
           <td>
            <b-button variant="secondary" @click="addCheckbox">Vložit zaškrtávací políčko</b-button>
          </td>
          <td>
            <b-button variant="secondary" @click="addImgWrap">Vložit obtékání textu u obrázku</b-button>
          </td>
        </tr>
      </table>
   
    </div>

    <ModalDialog ref="modalTestList"  
    :message = dialogMessage
    :entrylabel=dialogLabel
    :isVisible = showModal
    :showEdit=dialogShowEdit
    :enterValue=dialogDefaultValue
    @update:response="handleDialogClose" />

  </div>
</template>

<script>
import { getThPlanLevel, saveThPlan } from '../services/api';
import { quillEditor } from 'vue3-quill';
import ModalDialog from "../components/modalDialog.vue";

export default {
  name: 'TherapeuticPlanPage',
  components: {
    quillEditor,
    ModalDialog,
  },
  data() {
    return {
      user: null,
      statPath: '',
      statCode: '',
      statText: '',
      thPlanContent: '',
      qContent: '',
      modalVisible: false,
      dialogMessage: "",
      dialogLabel: "",
      dialogShowEdit: false,
      dialogResponseValue: "",
      dialogDefaultValue: "",
      dialogResponseCallBack: null,
      showModal: false,
    
      editorState: {
        disabled: false, // Control the editor's disabled state
        options: {
          theme: 'snow',
          modules: {
            toolbar: [
            [{ 'header': [1, 2, false] }], // Header options
            [{ 'size': ['small', false, 'large', 'huge'] }], // Text size options
            ['bold', 'italic', 'underline', 'strike'], // Text formatting
            [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Lists
            ['blockquote', 'code-block'], // Quotes and code blocks
            ['link', 'image'], // Insert options / video
            [{ 'color': [] }, { 'background': [] }], // Color options
            [{ 'align': [] }], // Alignment options
            ['clean'] // Remove formatting button
            ],
            clipboard: {
              matchVisual: false, // Prevents class references on paste
            },
          },
        },
      },
    };
  },

  methods: {
   
    closeModal() {
      this.modalVisible = false;
    },
    handleDialogClose(newVal) {
      //console.log(newVal)
      this.dialogResponseValue = newVal;
      this.showModal = false;
      if (newVal != "") {
        this.dialogResponseCallBack();
      }
    },


    async saveForm() {
     
      console.log(this.statPath);
      console.log(this.thPlanContent);
      const response = await saveThPlan(this.statCode, this.statPath, "", "", this.thPlanContent, '', '0');
      console.log(response);
    },

    async saveQuill() {
      //console.log(this.qContent);
      console.log("savingQuill.... " + this.statCode);
      const response = await saveThPlan(this.statCode, this.statPath, "", "", this.qContent, '', '0');
      console.log(response);
      this.$router.go(-1); 
    },
    cancelAction(){
      this.$router.go(-1); 
    },

    async loadThPlan() {
      try {
        const response = await getThPlanLevel(this.statPath);
        //console.log('Response received:', response);

        // Validate the response structure
        if (response.data && response.data.data && response.data.data.length > 0) {
          this.thPlanContent = response.data.data || '';
          this.qContent = this.thPlanContent;
        } else {
          console.warn('No valid data found in response.');
          this.thPlanContent = '';
        }
      } catch (error) {
        console.error('Error loading ThPlan:', error);
        this.thPlanContent = '';
      }
    },

    onEditorBlur() {
      console.log('Editor blurred');
    },

    onEditorFocus(event) {
      console.log('Editor focused ' + event.selection.lastRange.index);
    },

    onEditorReady() {
      console.log('Editor is ready');
    },

    onEditorChange(delta, oldDelta, source) {
      console.log('Editor content changed:', delta);
      console.log('Editor content changed:', oldDelta);
      console.log('Editor content changed:', source);
      // You can sync content with your data model here if needed
    },

    addEditField() {
      this.modalVisible = true;
      this.dialogMessage = "Zadejte popis editačního pole:";
      this.dialogShowEdit = true;
      this.showModal = true;
      this.dialogResponseCallBack = () => {
        var htmlToInsert = '_editbox(' +  this.dialogResponseValue + ',hodnota)';
        this.qContent = this.qContent + htmlToInsert;
      };
    },

    addCheckbox() {
      this.modalVisible = true;
      this.dialogMessage = "Zadejte popis zaškrtávacího políčka:";
      this.dialogShowEdit = true;
      this.showModal = true;
      this.dialogResponseCallBack = () => {
        var htmlToInsert = '_checkbox(' +  this.dialogResponseValue + ',0)';
        this.qContent = this.qContent + htmlToInsert;
     };
    },

    addImgWrap() {
      this.modalVisible = true;
      this.dialogMessage = 'Zadejte umístění obrázku vzhledem k textu a šířku obrázku v pixelech, např. "left, 200". Značku umístěte před obrázek.';
      this.dialogShowEdit = true;
      this.showModal = true;
      this.dialogResponseCallBack = () => {
        var htmlToInsert = '_img(' +  this.dialogResponseValue + ')';
        this.qContent = this.qContent + htmlToInsert;
     };

        //var htmlToInsert = '_img(left, 200)';
        //this.qContent = this.qContent + htmlToInsert;
     
    },

    makeResizable() {
      const resizers = document.querySelectorAll('.resizer');
      resizers.forEach(resizer => {
        resizer.addEventListener('mousedown', this.initResize);
      });
    },
    initResize(e) {
      const resizer = e.target;
      const img = resizer.parentElement.querySelector('img');
      const startWidth = img.clientWidth;
      const startHeight = img.clientHeight;
      const startX = e.clientX;
      const startY = e.clientY;

      const doDrag = (e) => {
        img.style.width = `${startWidth + e.clientX - startX}px`;
        img.style.height = `${startHeight + e.clientY - startY}px`;
      };

      const stopDrag = () => {
        window.removeEventListener('mousemove', doDrag);
        window.removeEventListener('mouseup', stopDrag);
      };

      window.addEventListener('mousemove', doDrag);
      window.addEventListener('mouseup', stopDrag);
    },
 
  },

  created() {
    if (this.$route.params.stat) {
      this.statPath = this.$route.params.stat;
      this.statCode = this.$route.params.code;

      //console.log("++++++")
      //console.log(this.statCode);

      this.statText = this.statPath;
      this.loadThPlan();
    }
  },
};
</script>

<style scoped>
.signature-image {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
.full-width-table td {
  padding: 8px;
}
.full-width-table-framed td {
  border: 1px solid #848484;
  padding: 8px;
}
.left-align {
  text-align: left;
}
.footer-table {
  width: 100%;
}
.input-border {
  border: 1px solid lightgrey;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.2s;
}
.input-no-border {
  border: 1px solid white;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.2s;
}
.input-border:focus {
  border-color: #007BFF;
  outline: none;
}
</style>