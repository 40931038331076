<template>
    <div ref="treeContainer"></div>
</template>

<script>
import * as d3 from 'd3';

export default {
    name: 'D3Tree',
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    mounted() {
        this.renderTree();
    },
    watch: {
        data: {
            handler() {
                this.renderTree();
            },
            deep: true
        }
    },
    methods: {
        renderTree() {
            //console.log('Rendering tree...');
            d3.select(this.$refs.treeContainer).selectAll("*").remove(); // Clear the container
            
            const data = this.data;
            const width = 1200;
            const height = 7000;
            const treeLayout = d3.tree().size([height, width]);

            const root = d3.hierarchy(data);
            treeLayout(root);

            const svg = d3.select(this.$refs.treeContainer)
              .append('svg')
              .attr('width', width + 150)
              .attr('height', height + 100)
              .append('g')
              .attr('transform', 'translate(50,50)');

            // Links
            svg.selectAll('path.link')
              .data(root.links())
              .enter()
              .append('path')
              .attr('class', 'link')
              .attr('d', d3.linkHorizontal()
                .x(d => d.y)
                .y(d => d.x));

            // Nodes
            const node = svg.selectAll('g.node')
              .data(root.descendants())
              .enter()
              .append('g')
              .attr('class', d => d.data.state === 1 ? 'nodeh' : 'node')
              .attr('transform', d => `translate(${d.y},${d.x})`)
              .on('click', (event, d) => this.handleNodeClick(event, d));

            const nodeGroup = node.append('g')
              .attr('class', 'node-group');

            nodeGroup.append('circle')
              .attr('r',  5)
              //.attr('r',  d => d.data.state === 1 ? 10 : 5)
           
            nodeGroup.append('text')
              .attr('dx', 10)
              .attr('dy', 3)
              //.text(d => d.data.name );
              .text(d => d.data.name.length > 10 ? d.data.name.substring(0, 14) + '...' : d.data.name);
        },

        handleNodeClick(event, node) {
            //console.log(`Node clicked:: ${node.data.name} ${node.data.id}`);
            this.$emit('node-clicked', node);
            this.selectedNode = node;
  
      },
        
    }
}
</script>

<style>
.link {
    fill: none;
    stroke: #c5cbef;
    stroke-width: 1.0px;
}

.node circle {
    fill: white;
    stroke: steelblue;
    stroke-width: 3px;
}

.node text {
    font: 12px sans-serif;
}

.nodeh circle {
    fill: rgb(211, 129, 129);
    stroke: steelblue;
    stroke-width: 2px;
}
.nodeh text {
    font: 12px sans-serif;
}
</style>