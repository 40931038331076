// src/eventBus.js
import { reactive, watch } from 'vue';

const eventBus = reactive({});

export function emit(event, data) {
  eventBus[event] = data;
}

export function on(event, callback) {
  watch(
    () => eventBus[event],
    (newValue) => {
      if (newValue !== undefined) {
        callback(newValue);
      }
    },
    { immediate: true }
  );
}