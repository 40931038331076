// src/services/api.js
import axios from 'axios';

//const API_URL = 'https://braille.cz/test/server/'; // Replace with your API URL

const apiClient = axios.create({
  baseURL: '', 
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getItems = () => {
  return apiClient.get('/items'); // Adjust endpoint as needed
};

export const createItem = (data) => {
  return apiClient.post('/items', data); // Adjust endpoint as needed
};


export const getPeople = () => {
  return apiClient.post('api', {
        method: "getIcUserList",
        scopekey: "", //this.$store.state.account.scopekey, 
        scopeval: "", //this.$store.state.account.scopevalue,
      }); 
  };

  export const getDiagList = () => {
    return apiClient.post('api', {
          method: "getDiagList",
        }); 
  };

  export const getStat = () => {
    return apiClient.post('api', {
          method: "getConfig",
          name: "stat",
        }); 
  };
  export const saveStat = (value) => {
    return apiClient.post('api', {
        method: "saveConfig",
        name: "stat",
        value: value,
      }); 
  };

  export const getPeopleEx = () => {
    const dataToSend = { message: 'Hello from Vue!' };
          return axios.post('https://ickapp.cz/api.php', dataToSend, {  //backend/server/api.php
           // withCredentials : true,
             headers: {
               'Content-Type': 'application/json'
             }
          });
        
    // return apiClient.post('api', {
      //     method: "getIcUserList",
      //     scopekey: "", //this.$store.state.account.scopekey, 
      //     scopeval: "", //this.$store.state.account.scopevalue,
      //   }); 
    };

  export const getIcUserById = (id) => {
    return apiClient.post('api', {
        method: "getIcUserDetail",
        id: id,
      }); 
  };

  export const getIcUserByPid = (pid) => {
    return apiClient.post('api', {
        method: "getIcUserByPid",
        pid: pid,
      }); 
  };


  export const saveIcUser = (user) => {
    return apiClient.post('api', {
        method: "saveIcUser",
        user: user,
      }); 
  };

  export const syncIcUser = (user) => {
    return apiClient.post('api', {
        method: "syncIcUser",
        user: user,
      }); 
  };

  export const addIcUser = (user) => {
    user.id = null;
    return apiClient.post('api', {
        method: "addIcUser",
        ic_user: user,
      }); 
  };


  export const deleteIcUserById = (id) => {
    return apiClient.post('api', {
        method: "deleteIcUser",
        id: id,
      }); 
  };

  export const getDocuments = (id) => {
    return apiClient.post('api', {
        method: "getIcDocuments",
        id: id, 
      }); 
  };

  export const getCatDocuments = (id, catId) => {
    return apiClient.post('api', {
        method: "getIcCatDocuments",
        id: id, 
        catId: catId, 
      }); 
  };

  export const getUserStats = (idUser) => {
    return apiClient.post('api', {
        method: "getIcUserStats",
        idUser: idUser, 
      }); 
  };

  export const addUserStat = (idUser, statDate, statPath, statDiagnosis, statOperation, statNote) => {
    return apiClient.post('api', {
        method: "addIcUserStat",
        idUser: idUser, 
        statDate: statDate,
        statPath: statPath,
        statDiagnosis: statDiagnosis,
        statOperation: statOperation,
        statNote: statNote,
      }); 
  };

  export const saveUserStat = (id, statCode, statDate, statPath, statDiagnosis, statOperation, statNote, patientId) => {
    return apiClient.post('api', {
        method: "saveIcUserStat",
        id: id, 
        statCode: statCode,
        statDate: statDate,
        statPath: statPath,
        statDiagnosis: statDiagnosis,
        statOperation: statOperation,
        statNote: statNote,
        patient_id: patientId,
      }); 
  };

  export const getUserStatMeta = (id) => {
    return apiClient.post('api', {
        method: "getIcUserStatMeta",
        id: id, 
      }); 
  };
  export const saveUserStatMeta = (id, meta) => {
    return apiClient.post('api', {
        method: "saveIcUserStatMeta",
        id: id, 
        meta: meta,
      }); 
  };

  export const getThPlan = (stat) => {
    return apiClient.post('api', {
        method: "getIcThPlan",
        stat: stat,
      }); 
  };
  export const getThPlanLevel = (stat) => {
    return apiClient.post('api', {
        method: "getIcThPlanLevel",
        stat: stat,
      }); 
  };

  export const saveThPlan = (code, stat, diagnosis, operation, content, meta, type) => {
    return apiClient.post('api', {
        method: "saveIcThPlan",
        code: code,
        stat: stat,
        diagnosis: diagnosis,
        operation: operation,
        content: content,
        meta: meta,
        type: type,
      }); 
  };

  export const deleteUserStat = (id) => {
    return apiClient.post('api', {
        method: "deleteIcUserStat",
        id: id,
      }); 
  };

  export const getFiles = (id) => {
    return apiClient.post('api', {
        method: "getIcFiles",
        id: id, 
        //responseType: 'blob',
      }); 
  };

  export const saveIcFile = (idUser, fileName, filePath, fileType) => {
    return apiClient.post('api', {
        method: "saveIcFile",
        userid: idUser,
        filename: fileName, 
        path: filePath,
        type: fileType,
      }); 
  };

  export const saveIcCatFile = (idUser, fileName, filePath, fileType, subtype, catId) => {
    return apiClient.post('api', {
        method: "saveIcCatFile",
        userid: idUser,
        filename: fileName, 
        path: filePath,
        type: fileType,
        subtype: subtype,
        catId: catId,
      }); 
  };

  export const deleteIcFile = (pid, id) => {
    return apiClient.post('api', {
        method: "deleteIcFile",
        id: id,
        pid: pid,
      }); 
  };

  export const sendIcMail = (id) => {
    return apiClient.post('api', {
        method: "sendIcMail",
        id: id,
      }); 
  };


export const setBaseURL = (serverUrl) => {
  //console.log(serverUrl);
    apiClient.defaults.baseURL = serverUrl;
  };